import Vue from "vue";
import App from "./App.vue";

Vue.config.productionTip = false;

var VueScrollTo = require("vue-scrollto");

Vue.use(VueScrollTo);

new Vue({
	render: (h) => h(App),
	methods: {
		handleScroll(el) {
			if (window.scrollY > 50) {
				el.setAttribute(
					"style",
					"opacity: 1; transform: translate3d(0, -10px, 0)"
				);
			}
			return window.scrollY > 100;
		},
	},
}).$mount("#app");

function initAnimacaoScroll() {
	const sections = document.querySelectorAll(".animation-scroll");
	const windowsMetade = window.innerHeight * 0.5;
	function animaScroll() {
		sections.forEach((section) => {
			const sectionTop = section.getBoundingClientRect().top - windowsMetade;
			if (sectionTop < 0) {
				section.classList.add("ativo");
			}
			window.addEventListener("scroll", animaScroll);
		});
	}
	animaScroll();
}
initAnimacaoScroll();
let now = new Date();
document.getElementById("date-footer").innerText = "© " + now.getFullYear();
