<template>
  <section id="contato">
    <div class="left">
      <h2>Contato</h2>
      <h3>Disponivel para novos Projetos</h3>
      <div class="contato">
        <p>(11) 9 5127 4482</p>
        <p>pedrooberto@hotmail.com</p>
        <p>A vida só começa depois do café.</p>
      </div>
    </div>
    <div class="right">
      <h3>Midias Sociais</h3>
      <div class="social">
        <a href="https://www.linkedin.com/in/pedrooberto/" target="_blank">
          <img src="@/assets/img/social/linkedin.svg" alt="Icone Linkedin" />
          <span>Linkedin</span>
        </a>
        <a href="https://www.behance.net/pedrooberto" target="_blank">
          <img src="@/assets/img/social/behance.svg" alt="Icone Behance" />
          <span>Behance</span>
        </a>
        <a href="https://www.facebook.com/pedrooberto.desenvolvedor.web/" target="_blank">
          <img src="@/assets/img/social/facebook.svg" alt="Icone Facebook" />
          <span>Facebook</span>
        </a>
        <!-- <a href="https://www.instagram.com/dev.pedrooberto/" target="_blank">
          <img src="@/assets/img/social/instagram.svg" alt="Icone Instagram" />
          <span>Instagram</span>
        </a> -->
        <a href="https://github.com/PedroOberto" target="_blank">
          <img src="@/assets/img/social/github.svg" alt="Icone GitHub" />
          <span>GitHub</span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>


<style lang="scss" scoped>
#contato {
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  h3 {
    text-transform: uppercase;
  }
  h2,
  h3,
  .social {
    margin-left: 10%;
    margin-top: 50px;
    margin-bottom: 50px;
    position: relative;
    z-index: 4;
  }
  .left {
    background-color: #333333;
    position: relative;
    height: 90vh;
    width: 100%;

    &::before {
      content: "CONTATO";
      position: absolute;
      left: -60px;
      top: 80px;
      font-weight: bold;
      color: #1c1c1c;
      font-size: 250px;
      line-height: 200px;
      writing-mode: vertical-rl;
      overflow: hidden;
      text-overflow: clip;
      height: 520px;
      @media only screen and (min-width: 768px) {
        writing-mode: horizontal-tb;
        left: -125px;
        height: 200px;
        width: 800px;
      }
    }
    &::after {
      content: "CONTATO";
      position: absolute;
      right: -50px;
      bottom: 80px;
      font-weight: bold;
      color: #1c1c1c;
      font-size: 250px;
      line-height: 200px;
      writing-mode: vertical-rl;
      overflow: hidden;
      text-overflow: clip;
      height: 520px;
      direction: rtl;
      @media only screen and (min-width: 768px) {
        writing-mode: horizontal-tb;
        right: -125px;
        height: 200px;
        width: 800px;
        direction: rtl;
      }
    }
    .contato {
      display: flex;
      flex-direction: column;
      justify-content: center;
      max-width: 70%;
      height: 200px;
      background-color: #1c1c1c;
      position: absolute;
      bottom: 100px;
      p {
        position: relative;
        max-width: 100%;
        left: 50px;
        z-index: 2;
        margin: 2px;
      }
      p:nth-child(3) {
        margin-top: 20px;
      }
    }
  }
  .right {
    width: 100%;
    .social {
      a {
        display: flex;
        align-items: center;
        color: #e7e7e7;
        img {
          width: 50px;
          margin-right: 8px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #contato {
    h2,
    h3 {
      margin-left: 160px;
    }
    .left {
      height: 90vh;
      max-width: 50%;
      .contato {
        max-width: 350px;
        p {
          max-width: 350px;
          left: 100px;
        }
      }
    }
    .right {
      max-width: 50%;
      h3 {
        margin-top: 145px;
        margin-left: 100px;
      }
      .social {
        margin-left: 100px;
      }
    }
  }
}
</style>