<template>
  <section id="short-bio" class="short-bio">
    <div class="left">
      <h1>Pedro Oberto</h1>
      <div class="bio">
        <p>
          <span>Desenvolvedor Front-end UI</span>, Geek e amante de leituras. Formado em Sistemas para Internet na Fiap e Desenhista de Páginas para Web pelo SENAI.
        </p>
      </div>
      <img class="foto" src="@/assets/img/Pedro.webp" alt="Foto de Pedro" />
    </div>
    <div class="right"></div>
    <svg class="scroll" version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
      <g fill="#0a7f91">
        <path
          d="M886 1731c-75-76-87-92-84-117 2-24 8-30 32-32 24-3 40 7 97 64l69 68 69-68c57-57 73-67 97-64 24 2 30 8 32 32 3 25-9 41-84 117-65 66-95 89-114 89s-49-23-114-89z"
        />
        <path
          d="M886 1511c-75-76-87-92-84-117 2-24 8-30 32-32 24-3 40 7 97 64l69 68 69-68c57-57 73-67 97-64 24 2 30 8 32 32 3 25-9 41-84 117-65 66-95 89-114 89s-49-23-114-89z"
        />
        <path
          d="M860 1238c-77-30-169-123-198-201-20-54-22-76-22-317 0-314 5-337 104-436 78-78 143-104 256-104s178 26 256 104c99 99 104 122 104 436 0 241-2 263-22 317-29 80-121 172-201 201-78 30-200 29-277 0zm258-94c59-29 106-79 136-143 19-43 21-63 21-281s-2-238-21-281c-50-108-142-169-253-169-114 0-204 59-253 165-22 48-23 61-23 285 0 261 4 283 74 362 77 88 214 114 319 62z"
        />
        <path d="M964 587c-2-7-4-52-2-98l3-84h70v190l-33 3c-21 2-34-2-38-11z" />
      </g>
    </svg>
    <a
      href="https://api.whatsapp.com/send?phone=5511951274482&text=Ol%C3%A1"
      target="WhatsApp"
      class="whatsapp"
      alt="WhatsApp Pedro Oberto"
    >
      <img src="@/assets/img/social/whatsapp.svg" alt="Icone WhatsApp" />
    </a>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.short-bio {
  height: 100vh;
  display: flex;
  .left {
    width: 100%;
    height: 100vh;
    position: relative;
    .bio {
      display: flex;
      align-items: center;
      max-width: 70%;
      height: 200px;
      background-color: #333333;
      position: absolute;
      bottom: 20%;
      p {
        span {
          color: #0a7f91;
        }
        position: relative;
        max-width: 100%;
        left: 50px;
        z-index: 2;
      }
    }
    .foto {
      width: 150px;
      height: 150px;
      border-radius: 100%;
      position: absolute;
      top: 120px;
      right: 10%;
      z-index: 2;
    }
  }
  .right {
    display: none;
  }

  .scroll {
    width: 80px;
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    bottom: 30px;
    z-index: 2;
  }
  .scroll path:nth-child(1),
  .scroll path:nth-child(2),
  .scroll path:nth-child(4) {
    animation: scroll-anim 1s ease infinite;
  }
  @keyframes scroll-anim {
    0% {
      transform: translate3d(0, 0, 0);
    }

    50% {
      transform: translate3d(0, 10%, 0);
    }

    100% {
      transform: translate3d(0, 0, 0);
    }
  }
  .whatsapp {
    width: 60px;
    height: 60px;
    background-color: #0a7f91;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 35px;
    }
    z-index: 3;
  }
}
@media only screen and (min-width: 768px) {
  .short-bio {
    .left {
      width: 50%;
      .bio {
        display: flex;
        align-items: center;
        max-width: 350px;
        height: 200px;
        background-color: #333333;
        position: absolute;
        bottom: 100px;
        p {
          max-width: 350px;
          left: 100px;
        }
      }
      .foto {
        width: 300px;
        height: 300px;
        top: 120px;
        right: -250px;
      }
    }
    .right {
      display: block;
      background-color: #333333;
      width: 50%;
      height: 100vh;
      position: relative;
      &::before {
        content: "PEDRO OBERTO";
        position: absolute;
        left: -25px;
        bottom: 100px;
        font-weight: bold;
        color: #1c1c1c;
        font-size: 250px;
        line-height: 200px;
      }
    }
    .whatsapp {
      width: 80px;
      height: 80px;
      img {
        width: 40px;
      }
    }
  }
}
</style>