<template>
  <section id="valores">
    <h2>Valores</h2>
    <!-- <div class="span-bg">
      <span>VALORES</span>
      <span>VALORES</span>
    </div>-->
    <div class="container cards">
      <div class="card">
        <img src="@/assets/img/250ml.svg" alt="Icone Site Institucional" />
        <h3>
          Cup™
          <span>250ml</span>
        </h3>
        <ul>
          <li>Long-Page ou até 5 páginas</li>
          <li>Formulario de contato</li>
          <li>Prazo de até um mês</li>
          <li>Sem CMS</li>
        </ul>
        <span class="preco">3x R$300,00</span>
      </div>
      <div class="card">
        <img src="@/assets/img/500ml.svg" alt="Icone Site Institucional" />
        <h3>
          Cup™
          <span>500ml</span>
        </h3>
        <ul>
          <li>Long-Page ou até 5 páginas</li>
          <li>Formulario de contato</li>
          <li>Prazo de até um mês</li>
          <li>Com CMS</li>
        </ul>
        <span class="preco">3x R$500,00</span>
      </div>
      <div class="card">
        <img src="@/assets/img/750ml.svg" alt="Icone Site Institucional" />
        <h3>
          Cup™
          <span>750ml</span>
        </h3>
        <ul>
          <li>Superior 5 páginas</li>
          <li>Formulario de contato</li>
          <li>Prazo a determinar</li>
        </ul>
        <span class="preco">Necessário Orçamento</span>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
#valores {
  position: relative;
  background-color: #333333;
  padding: 50px 0;
  .card {
    img {
      width: 60px;
      height: 60px;
    }
  }
  &::before {
    content: "VALORES";
    position: absolute;
    left: -60px;
    top: 80px;
    font-weight: bold;
    color: #1c1c1c;
    font-size: 250px;
    line-height: 200px;
    writing-mode: vertical-rl;
    @media only screen and (min-width: 768px) {
      writing-mode: horizontal-tb;
      left: -125px;
    }
  }
  &::after {
    content: "VALORES";
    position: absolute;
    right: -50px;
    bottom: 80px;
    font-weight: bold;
    color: #1c1c1c;
    font-size: 250px;
    line-height: 200px;
    writing-mode: vertical-rl;
    @media only screen and (min-width: 768px) {
      writing-mode: horizontal-tb;
      right: -125px;
    }
  }
}
</style>