<template>
  <header :class="{menuBg: scrolled}">
    <div class="container">
      <div class="header">
        <div class="pedrooberto_logo">
          <a
            href="#"
            v-scroll-to="{
                  el: '#short-bio',
                  offset: 0,
              }"
          >
            <img src="../../assets/img/pedrooberto_beans_logo.svg" alt="Logo Pedrooberto" />
          </a>
        </div>
        <div class="botao-menu" :class="{ativo: ativarMenu}" @click.prevent="menuAtivo">
          <div class="bar1"></div>
          <div class="bar2"></div>
          <div class="bar3"></div>
        </div>
      </div>
    </div>
    <nav class="menu" :class="{ativo: ativarMenu, inativo: menuInativo}">
      <ul>
        <li>
          <a
            href="#"
            v-scroll-to="{
                  el: '#institucional',
                  offset: -90,
              }"
            @click.prevent="menuAtivo"
          >Inicio</a>
        </li>
        <li>
          <a
            href="#"
            v-scroll-to="{
                  el: '#valores',
                  offset: -40,
              }"
            @click.prevent="menuAtivo"
          >Valores</a>
        </li>
        <li>
          <a
            href="#"
            v-scroll-to="{
                  el: '#projetos',
                  offset: -100,
              }"
            @click.prevent="menuAtivo"
          >Projetos</a>
        </li>
        <li>
          <a
            href="#"
            v-scroll-to="{
                  el: '#contato',
                  offset: -60,
              }"
            @click.prevent="menuAtivo"
          >Contato</a>
        </li>
      </ul>
    </nav>
  </header>
</template>
<script>
export default {
  name: "AppHeader",
  data() {
    return {
      ativarMenu: false,
      menuInativo: true,
      scrolled: false
    };
  },
  computed: {
    menu() {
      return document.querySelector(".menu");
    }
  },
  created() {
    window.addEventListener("scroll", this.menuBackground);
  },
  destroyed() {
    window.removeEventListener("scroll", this.menuBackground);
  },
  methods: {
    menuAtivo() {
      if (this.ativarMenu == false) {
        this.ativarMenu = true;
        this.menuInativo = false;
      } else {
        this.ativarMenu = false;
        this.menuInativo = true;
      }
    },
    menuBackground() {
      this.scrolled = window.pageYOffset > 200;
    }
  }
};
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  z-index: 5;
  margin: 0 auto;
  left: 0;
  right: 0;
  transition: all 0.5s;
  &.menuBg {
    background: #1c1c1c;
  }
  .header {
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    position: relative;
  }
  .pedrooberto_logo {
    width: 200px;
  }
}

.botao-menu {
  cursor: pointer;
  .bar1,
  .bar2,
  .bar3 {
    width: 35px;
    height: 5px;
    background-color: #0a7f91;
    margin: 6px 0;
    transition: 0.4s;
    border-radius: 4px;
  }
  &.ativo {
    .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      -moz-transform: rotate(-45deg) translate(-9px, 6px);
      -o-transform: rotate(-45deg) translate(-9px, 6px);
      -ms-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    .bar2 {
      opacity: 0;
    }
    .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      -moz-transform: rotate(45deg) translate(-8px, -8px);
      -o-transform: rotate(45deg) translate(-8px, -8px);
      -ms-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }
}
nav.menu {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: #1c1c1c;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    li a {
      color: #e7e7e7;
      padding: 8px;
      display: block;
      font-size: 24px;
    }
  }

  animation: fadeInDownBigOut 0.5s ease forwards;
  &.ativo {
    display: block;
    animation: fadeInDownBig 0.5s ease forwards;
  }
}
@media only screen and (min-width: 768px) {
  header {
    .header {
      height: 80px;
      z-index: 0;
    }
  }
  nav.menu {
    height: 80px;
    max-width: 500px;
    background: none;
    right: -200px;
    ul {
      height: 80px;
      flex-direction: row;
      li a {
        padding: 8px;
        font-size: 16px;
        margin: 0 20px;
      }
    }
    &.inativo {
      animation: fadeInRightBigOut 0.5s ease forwards;
    }
    &.ativo {
      animation: fadeInRightBig 0.5s ease forwards;
    }
  }
}
@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInDownBigOut {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}
@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(1000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes fadeInRightBigOut {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(1000px, 0, 0);
  }
}
</style>
