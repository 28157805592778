<template>
  <main>
    <AppShortBio />
    <AppInstitucional />
    <AppValores />
    <AppProjetos />
    <AppContato />
  </main>
</template>
<script>
import AppShortBio from "../components/shared/AppShortBio.vue";
import AppInstitucional from "../components/shared/AppInstitucional.vue";
import AppValores from "../components/shared/AppValores.vue";
import AppProjetos from "../components/shared/AppProjetos.vue";
import AppContato from "../components/shared/AppContato.vue";
export default {
  components: {
    AppShortBio,
    AppInstitucional,
    AppValores,
    AppProjetos,
    AppContato
  }
};
</script>
<style lang="scss">
</style>