<template>
  <div>
    <AppHeader />
    <Home />
    <AppFooter />
  </div>
</template>

<script>
import Home from "./components/Home.vue";
import AppHeader from "./components/shared/AppHeader.vue";
import AppFooter from "./components/shared/AppFooter.vue";

export default {
  components: {
    Home,
    AppHeader,
    AppFooter
  }
};
</script>
<style lang="scss">
/* ==========================================================================
   Reset
   ========================================================================== */

/**
 * Remove anchor text-decoration
 */

a {
  text-decoration: none;
}

/**
 * Remove list-style
 */

ol,
ul {
  list-style: none;
}

html,
body,
div,
span,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
a,
em,
img,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
article,
footer,
header,
nav,
section,
main {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
ul {
  font-size: 1em;
  font-weight: normal;
}
img {
  width: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  width: 960px;
  margin: 0 auto;
  padding: 0px;
  position: relative;
}

.container:after,
.container:before {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}
@media only screen and (min-width: 1200px) {
  .container {
    width: 1200px;
  }
}
@media only screen and (min-width: 960px) and (max-width: 1199px) {
  .container {
    width: 960px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 959px) {
  .container {
    width: 768px;
  }
}

@media only screen and (max-width: 767px) {
  .container {
    width: 300px;
  }
}
html,
body {
  background: url("../src/assets/img/background_beans.png");
  background-color: #1c1c1c;
  font-family: "Century Gothic";
  font-size: 16px;
  color: #e7e7e7;
  overflow-x: hidden;
}
h1,
h2 {
  font-size: 36px;
  line-height: 44px;
  color: #0a7f91;
  font-weight: bold;
  text-transform: uppercase;
  width: 140px;
  position: relative;
}
h3 {
  font-size: 24px;
  line-height: 29px;
  color: #e7e7e7;
  font-weight: bold;
  margin-bottom: 16px;
  span {
    font-weight: normal;
  }
}
h1 {
  margin-top: 150px;
  margin-left: 10%;
}
h2 {
  margin-left: 10%;
}
@media only screen and (min-width: 768px) {
  h1 {
    margin-top: 150px;
    margin-left: 160px;
  }
  h2 {
    margin-left: 160px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}
section {
  margin-bottom: 100px;
}
.cards {
  z-index: 2;
  .card {
    width: 300px;
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    img,
    svg {
      width: 100px;
      margin-bottom: 24px;
    }
    h3,
    p {
      text-align: center;
    }
    p {
      width: 100%;
    }
    ul li {
      text-align: center;
    }
    .preco {
      border: 2px solid #0a7f91;
      border-radius: 25px;
      font-size: 16px;
      margin-top: 32px;
      padding: 10px 20px;
    }
  }
  @media only screen and (min-width: 768px) {
    display: flex;
    justify-content: space-around;
    .card {
      margin: 10px 10px;
    }
  }
}
</style>


