<template>
  <section id="institucional">
    <h2>Site Institucional</h2>
    <div class="container cards">
      <div class="card">
        <img src="@/assets/img/institucional.svg" alt="Icone Site Institucional" />
        <h3>O que é?</h3>
        <p>Um site institucional é falar sobre sua empresa, sobre sua história e seus valores. Expor de forma clara como são oferecidos seus produdos ou serviços.</p>
      </div>
      <div class="card">
        <img src="@/assets/img/responsivo.svg" alt="Icone Site Institucional" />
        <h3>Responsivo</h3>
        <p>Um site responsivo é aquele que se encaixa automaticamente na tela do aparelho de seu cliente, enquadrando perfeitamente e com uma linda aparência, tanto pelo computador, tablet ou celular.</p>
      </div>
      <div class="card">
        <img src="@/assets/img/manutencao.svg" alt="Icone Site Institucional" />
        <h3>Manutenção</h3>
        <p>Ofereço atualização e manutenção de sites, um site precisa de cuidados, não basta estar online, precisa estar sempre perfeito, é da imagem do seu negocio que estamos falando.</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
</style>