var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{class:{menuBg: _vm.scrolled}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"header"},[_c('div',{staticClass:"pedrooberto_logo"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#short-bio',
                offset: 0,
            }),expression:"{\n                el: '#short-bio',\n                offset: 0,\n            }"}],attrs:{"href":"#"}},[_c('img',{attrs:{"src":require("../../assets/img/pedrooberto_beans_logo.svg"),"alt":"Logo Pedrooberto"}})])]),_c('div',{staticClass:"botao-menu",class:{ativo: _vm.ativarMenu},on:{"click":function($event){$event.preventDefault();return _vm.menuAtivo.apply(null, arguments)}}},[_c('div',{staticClass:"bar1"}),_c('div',{staticClass:"bar2"}),_c('div',{staticClass:"bar3"})])])]),_c('nav',{staticClass:"menu",class:{ativo: _vm.ativarMenu, inativo: _vm.menuInativo}},[_c('ul',[_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#institucional',
                offset: -90,
            }),expression:"{\n                el: '#institucional',\n                offset: -90,\n            }"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.menuAtivo.apply(null, arguments)}}},[_vm._v("Inicio")])]),_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#valores',
                offset: -40,
            }),expression:"{\n                el: '#valores',\n                offset: -40,\n            }"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.menuAtivo.apply(null, arguments)}}},[_vm._v("Valores")])]),_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#projetos',
                offset: -100,
            }),expression:"{\n                el: '#projetos',\n                offset: -100,\n            }"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.menuAtivo.apply(null, arguments)}}},[_vm._v("Projetos")])]),_c('li',[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: '#contato',
                offset: -60,
            }),expression:"{\n                el: '#contato',\n                offset: -60,\n            }"}],attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.menuAtivo.apply(null, arguments)}}},[_vm._v("Contato")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }